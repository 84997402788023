<template>
  <div class="main-wrapper">
    <div style="text-align: right">
      <el-button type="primary" @click="toAdd"><i class="el-icon-plus"></i> 添加试卷</el-button>
    </div>
    <el-table :data="paperList" class="customTable" style="width: 100%; margin-top: 15px; flex: 1" height="1%"
              :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
              :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
      <el-table-column prop="name" label="试卷名称"></el-table-column>
      <el-table-column prop="exam_grade" label="试卷等级"></el-table-column>
      <el-table-column prop="score" label="分值" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link type="success" @click="editPaper(scope.row.id)">编辑</el-link>
          <el-link type="danger" @click="deletePaper(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pages-center" style="margin: 20px 0 30px"
                   :current-page="listPages.currentPageNum"
                   :page-size="listPages.eachPageNum"
                   layout="prev, pager, next, jumper"
                   :total="listPages.total"
                   @current-change="pagesCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import { operationProblemOpEpList, operationProblemOpEpDel} from '@/utils/apis'
export default {
  name: "PaperManagement",
  data(){
    return {
      paperList:[],
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.getServiceList();
  },
  methods:{
    // 试卷列表
    getServiceList(){
      let params = {
        page:this.listPages.currentPageNum,
        limit:this.listPages.eachPageNum
      }
      operationProblemOpEpList(params).then((res)=>{
        this.paperList = res.data.list;
        this.listPages.total = res.data.total
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    toAdd(){
      this.$router.push('/practice/paperAddManagement')
    },
    editPaper(id){
      this.$router.push({
        path:'/practice/paperAddManagement',
        query:{
          id:id
        }
      })
    },
    deletePaper(id){
      this.$confirm(`此操作将永久删除该试卷，是否继续`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass:'green-message-box',
        type:'warning'
      }).then(()=>{
        let params = {
          id:id
        }
        operationProblemOpEpDel(params).then((res)=>{
          this.$message({
            message:res.msg,
            type:'success',
            duration:1000,
            onClose:()=>{
              this.getServiceList()
            }
          })
        }).catch((err)=>{
          console.log('err', err)
        })
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    pagesCurrentChange(val){
      this.listPages.currentPageNum = val;
      this.getServiceList();
    }
  }
}
</script>

<style scoped lang="scss">
.main-wrapper {
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}
</style>